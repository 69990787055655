import { useContext } from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";

export default function Header() {
  const navigate = useNavigate();

  const app = useContext(AppContext);

  const handleNavigate = (route?: string) => (e: any) => {
    const toggler = document.querySelector<HTMLButtonElement>(".navbar-toggler");

    if(!toggler?.classList.contains("collapsed")) {
      toggler?.click();
    }

    if (route) {
      if (route.startsWith("#") || route.startsWith("/#")) {
        e.preventDefault();

        navigate("/");

        setTimeout(() => {
          const element = document.querySelector(route.replace(/^\//, ""));
  
          if (element) {
            const scrollTop = element.getBoundingClientRect().top + window.scrollY - 50;
  
            window.scrollTo({
              top: scrollTop,
              behavior: "smooth",
            });
          }
        }, 100);

        return;
      }

      navigate(route);
    }
  };

  const handleLogout = (e: any) => {
    app.logout();
    handleNavigate("/login")(e);
  };

  return (
    <header>
      <Navbar id="main-navbar" expand="lg" fixed="top" className="py-3">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="bi bi-list" />
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-content-between">
            <Nav className="justify-content-between w-100 mt-3 mt-lg-0">
              <NavLink
                onClick={handleNavigate("#root")}
                className="nav-link text-center text-lg-start py-3 py-lg-0"
                to="/"
              >
                INÍCIO
              </NavLink>
              <NavLink
                onClick={handleNavigate("/#como-participar")}
                className="nav-link text-center text-lg-start py-3 py-lg-0"
                to="#como-participar"
              >
                COMO PARTICIPAR
              </NavLink>
              <NavLink
                onClick={handleNavigate()}
                className="nav-link text-center text-lg-start py-3 py-lg-0"
                to="/regulamento"
              >
                REGULAMENTO
              </NavLink>
              <NavLink
                onClick={handleNavigate()}
                className="nav-link text-center text-lg-start py-3 py-lg-0"
                to="/fale-conosco"
              >
                FALE CONOSCO
              </NavLink>
            </Nav>
            {!app.isAuthenticated && (
              <Nav className="ms-0 ms-lg-5 d-flex flex-row mt-5 mt-lg-0 align-items-center justify-content-center">
                <Nav.Item className="me-2">
                  <Button
                    className="button"
                    variant="primary"
                    size="sm"
                    onClick={handleNavigate("/login")}
                  >
                    LOGIN
                  </Button>
                </Nav.Item>
                <Nav.Item>
                  <Button
                    className="button"
                    variant="outline-primary"
                    size="sm"
                    onClick={handleNavigate("/#como-participar")}
                  >
                    CADASTRAR
                  </Button>
                </Nav.Item>
              </Nav>
            )}

            {app.isAuthenticated && (
              <Nav className="ms-0 ms-lg-5 d-flex flex-row mt-5 mt-lg-0 align-items-center justify-content-center">
                <Nav.Item className="me-2">
                  <Button
                    className="button"
                    variant="outline-primary"
                    size="sm"
                    onClick={handleNavigate("/meus-numeros")}
                  >
                    MEUS NÚMEROS
                  </Button>
                </Nav.Item>
                <Nav.Item>
                  <Button
                    className="button"
                    variant="outline-primary"
                    size="sm"
                    onClick={handleLogout}
                  >
                    SAIR
                  </Button>
                </Nav.Item>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
